import logo from "../../public/QRDGO_logo.svg";
import Image from "next/image";
import { FC } from "react";
import { Menu } from "./Menu";
import Link from "next/link";

interface Props {
  inhome: Boolean;
}

export const Navbar:FC<Props> = ({inhome}) => {
  return (
    <nav className={`py-2 md:py-6 transition-all ${inhome ? "absolute top-0 left-0 z-10 w-full" : "bg-nav" } `}>
      <div className="container">
        <div className="flex justify-between px-4 md:px-0">
          <div className="hidden md:visible md:block">
            <Link href={"/"}>
              <a>
                <Image src={logo} width={256} height={58} alt="QRDurango"/>
              </a>
            </Link>
          </div>
          <div className="visible block md:hidden">
            <Link href={"/"}>
              <a>
                <div className="w-36">
                  <Image src={logo} layout="responsive" alt="QRDurango"/>
                </div>
              </a>
            </Link>
          </div>
          <Menu />
        </div>
      </div>
    </nav>
  )
}
