import Image from "next/image";
import Durango from "../../public/related/durango.png";
import Mexico from "../../public/related/mexico.png";
import Museo from "../../public/related/museo.png";

export const Related = () => {
  return (
    <section className="bg-pink py-16 px-12 md:px-0">
      <div className="container grid grid-cols-1 md:grid-cols-3 gap-12">
        <div>
          <a href="http://visitdurango.mx/" target="_blank" rel="noreferrer">
            <div className="mb-4">
              <Image src={Durango} layout='responsive' alt="Sitio Oficial de Turismo de Durango | QRDgp"/>
            </div>
            <p className="text-white font-bold text-center m-0">Sitio Oficial de Turismo de Durango</p>
          </a>
        </div>
        <div>
          <a href="https://museodeartefunerariodurango.com/" target="_blank" rel="noreferrer">
            <div className="mb-4">
              <Image src={Museo} layout='responsive' alt="Museo Interactivo de Arte Funerario Benigno Montoya | QRDgp"/>
            </div>
            <p className="text-white font-bold text-center m-0">Museo Interactivo de Arte Funerario Benigno Montoya</p>
          </a>
        </div>
        <div>
          <a href="https://www.visitmexico.com/" target="_blank" rel="noreferrer">
            <div className="mb-4">
              <Image src={Mexico} layout='responsive' alt=" #DescubreMéxico | QRDgp"/>
            </div>
            <p className="text-white font-bold text-center m-0"> #DescubreMéxico</p>
          </a>
        </div>        
      </div>
    </section>
  )
}
