import Link from "next/link"

export const Menu = () => {
  return (
    <div>
      <ul className="flex uppercase">
        <li className="mr-3 md:mr-6">
          <Link href={"/"}>
            <a className="text-white hover:text-blue-800 text-sm md:text-md">Inicio
              <span className="w-1/2 border-b md:border-b-2 md:mt-1 border-yellow block m-auto"></span>
            </a>
          </Link>
        </li>
        <li className="md:mr-6 ">
          <Link href={"/folleteria"}>
            <a className="text-white hover:text-blue-800 text-sm md:text-md">Folletería</a>
          </Link>
        </li>
        <li className="mr-6 hidden md:visible md:block">
          <a className="text-white hover:text-blue-800" href="https://facebook.com/turismodurango" target="_blank" rel="noreferrer">
          <svg className="h-5 flex-no-shrink cursor-pointer fill-white" viewBox="0 0 10 21" fill="none">
            <path d="M2.55277 21V11.1462H0V7.59835H2.55277V4.56802C2.55277 2.18677 4.0607 0 7.53529 0C8.9421 0 9.98237 0.137655 9.98237 0.137655L9.9004 3.45074C9.9004 3.45074 8.83949 3.4402 7.68178 3.4402C6.42879 3.4402 6.22804 4.02956 6.22804 5.00776V7.59835H10L9.83588 11.1462H6.22804V21H2.55277Z"/>
          </svg>
          </a>
        </li>
        <li className="mr-6 hidden md:visible md:block">
          <a className="text-white hover:text-blue-800" href="https://instagram.com/turismodgo" target="_blank" rel="noreferrer">
            <svg className="h-5 flex-no-shrink cursor-pointer fill-white" viewBox="0 0 22 22" fill="none">
              <path d="M6 2C3.79086 2 2 3.79086 2 6V16C2 18.2091 3.79086 20 6 20H16C18.2091 20 20 18.2091 20 16V6C20 3.79086 18.2091 2 16 2H6ZM0 6C0 2.68629 2.68629 0 6 0H16C19.3137 0 22 2.68629 22 6V16C22 19.3137 19.3137 22 16 22H6C2.68629 22 0 19.3137 0 16V6ZM11.483 7.98924C10.8588 7.89668 10.2214 8.0033 9.66126 8.29392C9.10116 8.58455 8.64696 9.04438 8.36327 9.60802C8.07957 10.1717 7.98083 10.8104 8.08107 11.4334C8.18132 12.0564 8.47546 12.6319 8.92165 13.0781C9.36784 13.5243 9.94337 13.8184 10.5664 13.9187C11.1894 14.0189 11.8281 13.9202 12.3917 13.6365C12.9554 13.3528 13.4152 12.8986 13.7058 12.3385C13.9965 11.7784 14.1031 11.1409 14.0105 10.5167C13.9161 9.88005 13.6194 9.2906 13.1643 8.83547C12.7092 8.38034 12.1197 8.08365 11.483 7.98924ZM8.74012 6.51868C9.67362 6.0343 10.7361 5.85661 11.7764 6.01087C12.8375 6.16823 13.82 6.6627 14.5785 7.42126C15.3371 8.17981 15.8315 9.16222 15.9889 10.2234C16.1432 11.2637 15.9655 12.3261 15.4811 13.2596C14.9967 14.1931 14.2303 14.9501 13.2909 15.423C12.3515 15.8958 11.287 16.0604 10.2486 15.8933C9.2103 15.7262 8.25109 15.236 7.50744 14.4923C6.76379 13.7487 6.27356 12.7895 6.10647 11.7511C5.93939 10.7128 6.10397 9.64824 6.5768 8.70884C7.04962 7.76944 7.80662 7.00305 8.74012 6.51868ZM16.5 4.5C15.9477 4.5 15.5 4.94772 15.5 5.5C15.5 6.05228 15.9477 6.5 16.5 6.5H16.51C17.0623 6.5 17.51 6.05228 17.51 5.5C17.51 4.94772 17.0623 4.5 16.51 4.5H16.5Z"/>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  )
}
