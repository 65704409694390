import Image from "next/image"
import Logo from "../../public/QRDGO_logo.svg";
import LogoQR from "../../public/QRDGO_icon_blue_pink.svg";
import { Related } from "./Related";
import Link from "next/link";

export const Footer = () => {
  return (
    <>
      <Related />
      <footer>
        <div className="bg-purple-400 py-12">
          <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-5 mb-12">
              <div></div>
              <div></div>
              <div className="text-center px-24 md:px-0">
                <Image src={Logo} layout="responsive" alt="QRDgo"/>
              </div>
            </div>
            <div className="md:flex text-center md:text-left justify-center">
              <Image src={LogoQR} height={180} width={180} alt="QRDgo" />
              <div className="md:pl-4 md:ml-4 w-full md:w-1/2 pt-4 px-6 md:px-0">              
                <p className="text-white text-md mb-2">
                  Aquí encontrarás toda la <b>Oferta Turística en el Estado de Durango</b> disponible en categorias, podrás acceder a cada una de sus tarjetas digitales y obtener  información increible de cada una de estas empresas.
                </p>
                <div className="grid grid-cols-2 gap-16 md:gap-0 mt-6 md:mt-0 px-4 md:text-center">
                  <div>
                    <p className="text-white font-bold text-md md:text-lg">Contactar Soporte Técnico</p>
                    <p className="text-white text-xs font-light hover:text-yellow">
                      <Link href={"/soporte"}>
                        <a className="text-white">
                          Reporta problemas con este sitio
                        </a>
                      </Link>
                    </p>
                  </div>
                  <div>
                    <p className="text-white font-bold text-md md:text-lg">¿Quiéres estar aquí?</p>
                    <p className="text-white text-xs font-light hover:text-yellow">
                      <Link href={"/soporte"}>
                        <a className="text-white">
                          Ingresa tu negocio y se parte de esta aventura
                        </a>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subfooter bg-purple-500 text-center py-2 px-4 md:px-0">
          <p className="m-0 text-white text-xs md:text-md font-light"><b className="font-bold">QRDGO</b> | Plataforma  Turística Digital | Durango, Dgo. México | Todos los Derechos Reservados Ⓒ 2022</p>
        </div>
      </footer>
    </>
  )
}
